import './app-container.less';

import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'lodash/isEqual';

import NotArchived from '../error/not-archived';
import SearchToolbar from '../../components/search-toolbar';
import SparklineWithCapturesRange from '../../components/sparkline';

import { ErrorAndRetry } from '../error';
import Footer from '../footer';
import { ViewNavBar } from '../navbar';
import { Spinner } from '../spinner';
import { timestampToStr } from '../../utils/timestamp-url-parser';

export class AppLayout extends React.Component {
  static displayName = 'AppLayout';

  static propTypes = {
    children: PropTypes.node.isRequired,

    calendarSelection: PropTypes.object,
    explicitQueryType: PropTypes.string,
    isUrlInProgress: PropTypes.bool,
    isUrlError: PropTypes.string,
    isUrlNotArchived: PropTypes.bool,
    selectedView: PropTypes.string,
    showSparkline: PropTypes.bool,
    showViewNavBar: PropTypes.bool,
    url: PropTypes.string,
    hasCapturesOfSubmittedQueryIsValid: PropTypes.bool,

    onFetchHasCapturesOfSubmittedQuery: PropTypes.func.isRequired,
    onRetryIsUrl: PropTypes.func.isRequired
  };

  // calendarSelection was considered different when compared via `===` but has
  // the same value.
  shouldComponentUpdate (nextProps) {
    return (!isEqual(nextProps.calendarSelection, this.props.calendarSelection) ||
            nextProps.explicitQueryType !== this.props.explicitQueryType ||
            nextProps.url !== this.props.url ||
            nextProps.showViewNavBar !== this.props.showViewNavBar ||
            nextProps.showSparkline !== this.props.showSparkline ||
            nextProps.isUrlError !== this.props.isUrlError ||
            nextProps.isUrlNotArchived !== this.props.isUrlNotArchived ||
            nextProps.selectedView !== this.props.selectedView);
  }

  _fetchHasCapturesOfSubmittedQuery () {
    const {
      hasCapturesOfSubmittedQueryIsValid,
      onFetchHasCapturesOfSubmittedQuery
    } = this.props;

    if (hasCapturesOfSubmittedQueryIsValid) {
      return;
    }

    onFetchHasCapturesOfSubmittedQuery();
  }

  componentDidMount () {
    this._fetchHasCapturesOfSubmittedQuery();
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.url !== this.props.url
    ) {
      this._fetchHasCapturesOfSubmittedQuery();
    }
  }

  render () {
    const {
      children,
      calendarSelection,
      explicitQueryType,
      isUrlInProgress, isUrlError, isUrlNotArchived,
      selectedView,
      url,
      showViewNavBar, showSparkline,
      onRetryIsUrl
    } = this.props;

    const timestamp = calendarSelection?.explicit ? timestampToStr({ equal: calendarSelection }) : '*';

    return (
      <>
        <SearchToolbar selectedView={selectedView}/>
        {
          // I believe isUrlInProgress is true while loading type-ahead suggestsions
          // we don't need to show a spinner for it.
          (false && isUrlInProgress) ? (
            <div className="search-result-container-with-spinner">
              <Spinner/>
            </div>
          ) : (
            isUrlError ? (
              <div className="search-result-container container">
                <ErrorAndRetry error={isUrlError} onRetry={onRetryIsUrl}/>
              </div>
            ) : (
              // TODO: NotArchived is applicable to calendar and collections only.
              // move to the components for those query types.
              isUrlNotArchived ? (
                <NotArchived url={url}/>
              ) : (
                <>
                  {
                    showViewNavBar && (
                      <ViewNavBar
                        calendarSelection={calendarSelection}
                        explicitQueryType={explicitQueryType}
                        selected={selectedView}
                        url={url}
                        timestamp={timestamp}
                      />
                    )
                  }
                  {
                    // TODO: sparkline is specific to calendar and collections.
                    // move to the components for those query types.
                    showSparkline && (
                      <SparklineWithCapturesRange/>
                    )
                  }
                </>
              )
            )
          )
        }
        {children}
        <Footer/>
      </>
    );
  }
}
