import has from 'lodash/has';
import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

import { getHosts } from '../entities/hosts';

import * as searchRequestSelector from './search-request';
import extractFileTextAndType from '../../utils/parsers/extract-filetype';

// immediate query

export const getAutoSuggestionInProgress = createSelector(
  [getHosts, searchRequestSelector.getImmediateQueryText],
  (hosts, immediateText) => get(hosts, [immediateText, 'inProgress'], false)
);

export const getAutoSuggestionList = createSelector(
  [getHosts, searchRequestSelector.getImmediateQueryText],
  (hosts, immediateText) => get(hosts, [immediateText, 'hosts'])
);

export const hasAutoSuggestionList = createSelector(
  [getHosts, searchRequestSelector.getImmediateQueryText],
  (hosts, immediateQuery) => {
    const { text } = extractFileTextAndType(immediateQuery);
    return has(hosts, [text, 'hosts']);
  }
);

// submitted query

export const isQueryUrlInProgress = createSelector(
  [getHosts, searchRequestSelector.getSubmittedQueryText],
  (hosts, queryText) => get(hosts, [queryText, 'inProgress'], false)
);

// true if /__wb/search/host says queryText is not a URL (i.e. it should be treated as
// keyword search)
export const isQueryUrl = createSelector(
  [getHosts, searchRequestSelector.getSubmittedQueryText],
  (hosts, queryText) => get(hosts, [queryText, 'isUrl'], false)
);

// true if /__wb/search/host returns a response with "isUrl", regardless of value
export const isQueryUrlValid = createSelector(
  [getHosts, searchRequestSelector.getSubmittedQueryText],
  (hosts, queryText) => has(hosts, [queryText, 'isUrl'])
);

export const getError = createSelector(
  [getHosts, searchRequestSelector.getSubmittedQueryText],
  (hosts, queryText) => get(hosts, [queryText, 'error'])
);
